import React from 'react';
import './NavBar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="/" className="logo">
          MANDO
        </a>
      </div>
      <div className="navbar-center">
        <ul className="nav-links">
          <li><a href="https://mandoscan.com/#overview">Overview</a></li>
          <li><a href="https://mandoscan.com/#about">About MANDO</a></li>
          <li><a href="https://mandoscan.com/#how_it_works">How It Works</a></li>
          <li><a href="https://mandoscan.com/#product">Products</a></li>
          <li><a href="https://mandoscan.com/#bu_model">Business Models</a></li>
          <li><a href="https://mandoscan.com/#roadmap">Roadmap</a></li>
          <li><a href="https://mandoscan.com/#team">Team</a></li>
        </ul>
      </div>
      <div className="navbar-right">
        {/* <a href="/cart" className="cart-icon">
          <i className="fas fa-shopping-cart"></i>
          <span className="cart-count">0</span>
        </a> */}
        <a href="/account" className="user-icon">
          <i className="fas fa-user">Login/</i>
          <i className="fas fa-user">Sign up</i>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
