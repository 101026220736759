import React,{PureComponent,useCallback} from 'react';
import { ForceGraph2D} from 'react-force-graph';
import "./graph.css";
export default class Graph extends PureComponent{
    shouldComponentUpdate(nextProps) {
        if (this.props.ClickNode === nextProps.ClickNode && this.props.graph!=null) {
            console.log("graph-reload")
            return true;
        }
        return false;
    }
    
    render(){

      const data=this.props.graph
    //   var suggestionData = this.props.suggestion
    //   suggestionData = JSON.parse(suggestionData)
    //   for(var i=0;i<data['nodes'].length;i++){
    //       data['nodes']['suggestion'] = suggestionData[0]['improvement_suggestion']
    //     }
    //   console.log(data["nodes"])
    //   var suggestionText = '\nReason: ' + suggestionData[0]['reason'] 
                    //    + '\nSuggestion: ' + suggestionData[0]['improvement_suggestion']
    //   console.log(suggestionData)
    //   console.log(suggestionData[0])
      const nodes=data["nodes"].flat().map(app=>({id:app.id,name:app.name, nodeColor: app.color,code_lines:app.code_lines,error:app.error}))
    //   const nodes=data["nodes"].flat().map(app=>({id:app.id,name:app.name+ suggestionText, nodeColor: app.color,code_lines:app.code_lines,error:app.error, suggestion:app.suggestion}))
      // const nodes=data["nodes"].flat().map(app=>({id:app.id,name:app.name+ suggestionText, nodeColor: colorItems[randomNumberInRange(0,5)],code_lines:app.code_lines,error:app.error, suggestion:app.suggestion}))
      const nodeIdList = Array.from({ length: data["nodes"].length }, (_, i) => i);
      const links = data["links"].flat().map(app=>({"source":app.source,"target":app.target}))
      // const links = generateTreeEdges(nodeIdList)
      console.log('edges: ', links)
      const myGraph={nodes,links};
        
        const paintRing = (node, ctx) => {
            // add ring just for highlighted nodes
            let gData=myGraph;
            ctx.beginPath();
            ctx.arc(node.x, node.y, 5 * 1.8, 0, 2 * Math.PI, false);
            ctx.fillStyle = gData.nodes[node.id].nodeColor;
            ctx.fill();
            ctx.stroke();
          };

          
        const handleClick=(node)=>{
            let result=myGraph.nodes[node.id].code_lines;
            this.props.parentCallback(result);
        };
        const draw_red_circle=()=>{
        var canvas = document.getElementById('circle');
        if (canvas.getContext)
            {
            var ctx = canvas.getContext('2d'); 
            var X = canvas.width / 2;
            var Y = canvas.height / 2;
            var R = 45;
            ctx.beginPath();
            ctx.arc(X, Y, R, 0, 2 * Math.PI, false);
            ctx.lineWidth = 3;
            ctx.strokeStyle = '#FF0000';
            ctx.stroke();
            ctx.fill();
            }
        }
        return(
            <div onLoad={draw_red_circle}>
                <div className='comment'>
                <div className='line_6'>
                    <div className='col_6' ><span id='bg_loop' ></span>  BG Loop  </div>  
                    <div className='col_6' ><span id='other' ></span>  Other</div>  
                    <div className='col_6' ><span id='throw' ></span>  Throw</div>   
                    <div className='col_6' ><span id='new_var' ></span>New_var</div>   
                    <div className='col_6' ><span id='return' ></span>  Return</div>
                    <div className='col_6' ><span id='if' ></span>  If</div>  
                </div>
                <div className='line_6'>
                    <div className='col_6' ><span id='inline' ></span>  Inline ASM</div> 
                    <div className='col_6' ><span id='func' ></span>  Func</div> 
                    <div className='col_6' ><span id='end_loop' ></span>  End Loop</div>   
                    <div className='col_6' ><span id='ifloop' ></span>  If Loop</div> 
                    <div className='col_6' ><span id='_' ></span>  _</div>  
                    <div className='col_6' ><span id='endif' ></span> EndIf</div>
                </div>
                <div className='line_5'>
                    <div className='col_5_1' ><span id='entrypoint' ></span>  Entry_point</div>
                    <div className='col_5_1' ><span id='continue' ></span>  Continue</div>   
                    <div className='col_5_1' ><span id='expression' ></span>  Expression</div>   
                    <div className='col_5_2' ><span id='fallback_func' ></span>  Fallback_Func</div>  
                    <div className='col_5_2' ><span id='contract_func' ></span> Contract_Func</div>  
                </div>
                </div>
                <ForceGraph2D graphData={myGraph} 
                nodeRelSize={5} width={780} height={530}
                edgelinkWidth={2}
                nodeCanvasObject={paintRing}
                nodeCanvasObjectMode={node => 'before'}
                nodeColor={node=>myGraph.nodes[node.id].error==1? "red":"white"}
                linkDirectionalParticles={2}
                nodeLabel={node=>myGraph.nodes[node.id].name}
                onNodeClick={handleClick}
                />
            </div>
           
        )
    }
}

